<template>
  <div class="animated fadeIn">
    <div class="main-info">
      <div class="main-info__body">
        <h1 class="main-info__body-title">
          Габарит «{{ data.name }}»
        </h1>
        <b-row>
          <b-col
            md="4"
          >
            <div class="main-info__item">
              <p class="main-info__item-title">
                Тоннаж
              </p>
              <p class="main-info__item-desc">
                {{ data.weight }}
              </p>
            </div>
          </b-col>
          <b-col
            md="4"
          >
            <div class="main-info__item">
              <p class="main-info__item-title">
                Объем от
              </p>
              <p class="main-info__item-desc">
                {{ data.volumeFrom }}
              </p>
            </div>
          </b-col>
          <b-col
            md="4"
          >
            <div class="main-info__item">
              <p class="main-info__item-title">
                Объем до
              </p>
              <p class="main-info__item-desc">
                {{ data.volumeUpTo }}
              </p>
            </div>
          </b-col>
          <b-col
            md="4"
          >
            <div class="main-info__item">
              <p class="main-info__item-title">
                Основной габарит по умолчанию
              </p>
              <b-form-group
                id="acceleratedInputGroup3"
              >
                <b-form-checkbox
                  id="isDefault"
                  v-model="data.isDefault"
                  name="isDefault"
                  :disabled="true"
                >
                  Да
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <b-col
            md="4"
          >
            <div class="main-info__item">
              <p class="main-info__item-title">
                Дополнительный габарит по умолчанию
              </p>
              <b-form-group
                id="acceleratedInputGroup3"
              >
                <b-form-checkbox
                  id="isOptionalDefault"
                  v-model="data.isOptionalDefault"
                  name="isOptionalDefault"
                  :disabled="true"
                >
                  Да
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <h2 class="main-info__body-subtitle">
          Отношения опциональных габаритов
        </h2>
        <table class="main-info__table table b-table table-responsive">
          <thead>
            <tr>
              <th>ID</th>
              <th>Габариты ТС</th>
              <th>Процент</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dimension in calculate">
              <td>{{dimension.optionalDimension.id}}</td>
              <td>{{dimension.optionalDimension.name}}</td>
              <td>{{dimension.percentage}}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="fixed-buttons">
      <b-button
        type="button"
        variant="secondary"
        class="mr-2"
        :class="{'loading' : loading}"
        @click="$router.go(-1)"
      >
        Назад
      </b-button>
      <b-button
        v-if="$store.getters.isAdminOrManager"
        :href="`/dimensions/customer/edit/id${dimensionId}`"
        type="button"
        variant="primary"
        class="mr-2"
        :class="{'loading' : loading}"
        :disabled="loading"
      >
        Редактировать
      </b-button>
    </div>
  </div>
</template>

<script>
import {transportVehiclesDimensionTypeRead, dimensionCalculateRead} from '../../services/api';
import notifications from '../../components/mixins/notifications';

export default {
  name: 'CustomerDimensionsView',
  mixins: [notifications],
  props: ['dimensionId'],
  data() {
    return {
      data: {},
      calculate: {},
      loading: false,
    };
  },
  mounted() {
    this.getDimension();
    this.getDimensionCalculate();
  },
  methods: {
    async getDimension() {
      this.loading = true;
      const response = await transportVehiclesDimensionTypeRead(this.$route.params.dimensionId);
      if (response && response.status === 200) {
        this.data = response.data;
      }
      this.loading = false;
    },
    async getDimensionCalculate() {
      this.loading = true;
      const response = await dimensionCalculateRead(this.$store.state.user.ownerId, this.$route.params.dimensionId, {
        limit: 100,
      });
      if (response && response.status === 200) {
        this.calculate = response.data.items;
      }
      this.loading = false;
    },
  },
};
</script>
